/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
	Box, Heading, Text, VStack, HStack, Spacer, Textarea, Button, Divider, Flex, Icon,
	Center, Editable, EditableInput, EditablePreview, Grid, CircularProgress, Switch, Badge,
} from '@chakra-ui/react';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import useSWR from 'swr';
import { useDebounce } from 'use-debounce';
import Link from 'next/link';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import pangu from 'pangu';
import styles from '../../styles/datasets.module.css';
import axios from '../../utils/axios';
import Page from '../../components/Page';

function DataSelector({ text: _text, readOnly = false, setText = () => {} }) {
	const [text, _setText] = useState(_text);
	useEffect(() => {
		_setText(_text);
	}, [_text]);
	return (
		<Box
			shadow="sm"
			bg="white"
			w="10vw"
			minW="180px"
			maxW="250px"
			h="10vw"
			minH="180px"
			maxH="250px"
			p={3}
		>
			<Center w="100%" h="100%" textAlign="center">
				{readOnly ? (
					<Text fontWeight={600} fontSize="xl">{text}</Text>
				) : (
					text ? (
						<Editable defaultValue={text} placeholder="輸入關鍵字..." onSubmit={(t) => setText(t.trim().length ? t.trim() : ' ')}>
							<EditableInput />
							<EditablePreview fontSize="2xl" />
						</Editable>
					) : <CircularProgress isIndeterminate color="green.300" />
				)}
			</Center>
		</Box>
	);
}

/* eslint-disable */
function shuffle(array) {
	for (let i = array.length - 1; i > 0; i--) {
		let j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
}

function sleep(duration) {
	return new Promise(function(resolve) {
		setTimeout(()=> { resolve(0) }, duration);
	})
}
/* eslint-enable */

export default function Home() {
	// const [step, setStep] = useState(0);
	const { setStep, activeStep: step } = useSteps({
		initialStep: 0,
	});

	const [text, setText] = useState('');
	const [searchText] = useDebounce(text, 1000);
	const [keywords, setKeywords] = useState([]);
	const [sdgOnly, setSdgOnly] = useState(false);
	const { data: keywordsData } = useSWR(`/api/v1/similar/${searchText}?limit=20&${sdgOnly ? 'sdg=1' : ''}`);
	const [tempKey, setTempKey] = useState('');

	const [sample, setSample] = useState([]);

	const generateKeywords = async () => {
		if (keywordsData?.length) {
			shuffle(keywordsData);
		}

		Array(8).fill(0).forEach((_, i) => {
			const w = keywordsData?.[i]?.word ?? '';
			setTimeout(() => {
				setKeywords((k) => [...k, w]);
			}, Math.random() * 100 + 300 * i);
		});
	};

	useEffect(() => {
		if (!keywordsData?.length || step !== 1) return;
		generateKeywords();
	}, [keywordsData, step]);

	const generateSample = async () => {
		const keys = [...keywords];
		shuffle(keys);
		const c = [keys[0], keys[1]];
		setSample([...sample, c]);
		await sleep(Math.random() * 1000 + 500);
		const { data } = await axios.get(`/api/v1/datasets_beta?q=${encodeURIComponent(`${c[0]} ${c[1]}`)}&limit=5`);
		c[2] = { ...data?.hits?.hits[0], show: 1 };
		c[3] = { ...data?.hits?.hits[1], show: 0 };
		c[4] = { ...data?.hits?.hits[2], show: 0 };
		c[5] = { ...data?.hits?.hits[3], show: 0 };
		c[6] = { ...data?.hits?.hits[4], show: 0 };
		setSample([...sample, c]);
	};

	const getOneMore = async (i) => {
		const s = sample[i];
		const c = s.find((x) => x.show === 0);
		if (!c) return;
		c.show = 0.5;
		setSample([...sample.slice(0, i), s, ...sample.slice(i + 1)]);

		setTimeout(() => {
			c.show = 1;
			setSample([...sample.slice(0, i), s, ...sample.slice(i + 1)]);
		}, Math.random() * 500 + 500);
	};

	const generatePair = async (a, b) => {
		const c = [a, b];
		setSample([...sample, c]);
		await sleep(Math.random() * 1000 + 500);
		const { data } = await axios.get(`/api/v1/datasets_beta?q=${encodeURIComponent(`${c[0]} ${c[1]}`)}&limit=5`);
		c[2] = { ...data?.hits?.hits[0], show: 1 };
		c[3] = { ...data?.hits?.hits[1], show: 0 };
		c[4] = { ...data?.hits?.hits[2], show: 0 };
		c[5] = { ...data?.hits?.hits[3], show: 0 };
		c[6] = { ...data?.hits?.hits[4], show: 0 };
		setSample([...sample, c]);
	};

	const setKey = async (x) => {
		if (!tempKey) {
			setTempKey(x);
		} else {
			generatePair(tempKey, x);
			setTempKey('');
		}
	};

	const steps = [
		{ label: '主題描述', content: <></> },
		{ label: '九宮格思考', content: <></> },
		{ label: '產生資料集', content: <></> },
	];

	return (
		<Page>
			<Steps my={5} activeStep={step}>
				{steps.map(({ label, content }) => (
					<Step label={label} key={label}>
						{content}
					</Step>
				))}
			</Steps>
			{step === 0 ? (
				<Box mt={6}>
					<Heading as="h1" size="md">你想解決什麼...？</Heading>
					<Textarea type="text" mt={3} placeholder="輸入你想解決的問題" value={text} onChange={(e) => setText(e.target.value)} />
					<HStack mt={2}>
						<Text color="#777" ml={2}>{text.length < 15 ? '至少輸入 15 字吧！' : '很好，讓我們進行下一步！'}</Text>
						<Spacer />
						<HStack>
							<Box mr={2}>
								<Switch size="lg" onChange={(e) => setSdgOnly(e.target.checked)} />
								<Text color="#333" fontSize="md">SDG 關鍵字</Text>
							</Box>
							<Button colorScheme="blue" onClick={() => setStep(1)} disabled={text.length < 15}>下一步</Button>
						</HStack>
					</HStack>
				</Box>
			) : null}
			{step === 1 ? (
				<>
					<Box mt={6}>
						<Heading fontSize="2xl">改成你喜歡的關鍵字吧！</Heading>
					</Box>
					<VStack spacing={6}>
						<HStack spacing={6}>
							<DataSelector text={keywords[0]} setText={(e) => setKeywords([...keywords.slice(0, 0), e, ...keywords.slice(0 + 1)])} />
							<DataSelector text={keywords[1]} setText={(e) => setKeywords([...keywords.slice(0, 1), e, ...keywords.slice(1 + 1)])} />
							<DataSelector text={keywords[2]} setText={(e) => setKeywords([...keywords.slice(0, 2), e, ...keywords.slice(2 + 1)])} />
						</HStack>
						<HStack spacing={6}>
							<DataSelector text={keywords[3]} setText={(e) => setKeywords([...keywords.slice(0, 3), e, ...keywords.slice(3 + 1)])} />
							<Box>
								<Grid templateColumns="5px 1fr 5px">
									<Box>
										<Center w="100%" h="100%">
											{/* <Text fontWeight={900} fontSize="2xl" color="#ccc">↖</Text> */}
											<Icon as={Fa} icon={faArrowRight} color="#aaa" transform="rotate(-135deg)" />
										</Center>
									</Box>
									<Box>
										<Center w="100%" h="100%">
											{/* <Text fontWeight={900} fontSize="2xl" color="#ccc">↑</Text> */}
											<Icon as={Fa} icon={faArrowRight} color="#aaa" transform="rotate(-90deg)" />
										</Center>
									</Box>
									<Box>
										<Center w="100%" h="100%">
											{/* <Text fontWeight={900} fontSize="2xl" color="#ccc">↗</Text> */}
											<Icon as={Fa} icon={faArrowRight} color="#aaa" transform="rotate(-45deg)" />
										</Center>
									</Box>
								</Grid>
								<Grid templateColumns="5px 1fr 5px">
									<Box>
										<Center w="100%" h="100%">
											{/* <Text fontWeight={900} fontSize="2xl" color="#ccc">←</Text> */}
											<Icon as={Fa} icon={faArrowRight} color="#aaa" transform="rotate(-180deg)" />
										</Center>
									</Box>
									<HStack><DataSelector text={text} readOnly /></HStack>
									<Box>
										<Center w="100%" h="100%">
											{/* <Text fontWeight={900} fontSize="2xl" color="#ccc">→</Text> */}
											<Icon as={Fa} icon={faArrowRight} color="#aaa" transform="rotate(0deg)" />
										</Center>
									</Box>
								</Grid>
								<Grid templateColumns="5px 1fr 5px">
									<Box>
										<Center w="100%" h="100%">
											{/* <Text fontWeight={900} fontSize="2xl" color="#ccc">↙</Text> */}
											<Icon as={Fa} icon={faArrowRight} color="#aaa" transform="rotate(-215deg)" />
										</Center>
									</Box>
									<Box>
										<Center w="100%" h="100%">
											{/* <Text fontWeight={900} fontSize="2xl" color="#ccc">↓</Text> */}
											<Icon as={Fa} icon={faArrowRight} color="#aaa" transform="rotate(-270deg)" />
										</Center>
									</Box>
									<Box>
										<Center w="100%" h="100%">
											{/* <Text fontWeight={900} fontSize="2xl" color="#ccc">↘</Text> */}
											<Icon as={Fa} icon={faArrowRight} color="#aaa" transform="rotate(-315deg)" />
										</Center>
									</Box>
								</Grid>
							</Box>
							<DataSelector text={keywords[4]} setText={(e) => setKeywords([...keywords.slice(0, 4), e, ...keywords.slice(4 + 1)])} />
						</HStack>
						<HStack spacing={6}>
							<DataSelector text={keywords[5]} setText={(e) => setKeywords([...keywords.slice(0, 5), e, ...keywords.slice(5 + 1)])} />
							<DataSelector text={keywords[6]} setText={(e) => setKeywords([...keywords.slice(0, 6), e, ...keywords.slice(6 + 1)])} />
							<DataSelector text={keywords[7]} setText={(e) => setKeywords([...keywords.slice(0, 7), e, ...keywords.slice(7 + 1)])} />
						</HStack>
					</VStack>
					<Box textAlign="right">
						<Button
							colorScheme="green"
							onClick={() => {
								setKeywords([]);
								generateKeywords();
							}}
							mr={2}
						>
							重新產生
						</Button>
						{keywords.filter((x) => x && x.trim().length).length >= 8 && <Button colorScheme="blue" onClick={() => setStep(2)}>下一步</Button>}
					</Box>
				</>
			) : null}
			{step === 2 ? (
				<>
					<Box my={2}>
						<Heading fontSize="2xl">產生什麼資料集呢？</Heading>
					</Box>
					<Box mb={3}>
						<HStack>
							{keywords.map((x, i) => (
								// eslint-disable-next-line react/no-array-index-key
								<Button key={`${x}${i}`} p={3} background="#fff" onClick={() => setKey(x)}>
									<Text fontSize="lg" fontWeight="400">{x}</Text>
								</Button>
							))}
							<Button p={3} background="lightblue">
								<Text fontSize="lg" fontWeight="400" onClick={generateSample}>隨機抽一組</Text>
							</Button>
						</HStack>
					</Box>
					<Box>
						{sample.map((s, i) => (
							// eslint-disable-next-line react/no-array-index-key
							<Box key={`${s[0]}${s[1]}${i}`} mb={6}>
								<Box background="#fff" shadow="md" p={2} d="inline-block">
									{s[0]}
								</Box>
								<Text as="span" px={2} color="#777"> + </Text>
								<Box background="#fff" shadow="md" p={2} d="inline-block">
									{s[1]}
								</Box>
								<Text as="span" px={2} color="#777"> = </Text>
								<Box mb={5}>
									{!s[2] ? <CircularProgress mt={3} isIndeterminate colorScheme="blue.300" /> : null}
									{Array(5).fill(0).map((x, j) => j + 2).map((j) => {
										const x = s[j];
										return (x && x.show >= 1) ? (
											<Flex>
												<Box flex="1">
													<div className="mt-3 p-2 py-3 border border-secondary rounded shadow-sm" w="100%">
														<div className={`font-weight-bold ${styles.title}`}>
															<Link href={`/dataset/${x._id}`}>
																<a className="color-inherit">
																	{pangu.spacing(x.name || '')}
																</a>
															</Link>
														</div>
														<div>
															<div className="inline-block mr-2">
																{Array.from(new Set(x.resources.map((r) => r.format))).map((f) => (
																	<Badge className={`mr-1 ${styles.datatype_label}`} key={`${x.datasetId}-${f}`}>{f.toUpperCase()}</Badge>
																))}
																<small>
																	{x.government.name}
																</small>
															</div>
														</div>
														<div className={styles.desc}>
															{pangu.spacing(x?.desc?.slice(0, 100) || '')}
															{x?.desc?.length > 100 ? '……' : ''}
														</div>
													</div>
												</Box>
												<Box w="150px">
													<Flex h="100%" alignItems="flex-end" justifyContent="center">
														{ s.length > j - 1 && s[j + 1]?.show === 0 ? (
															<Button onClick={() => getOneMore(i)}>再抽一個資料集</Button>
														) : null }
													</Flex>
												</Box>
											</Flex>
										) : ((x?.show > 0) ? <CircularProgress mt={3} isIndeterminate colorScheme="blue.300" /> : null);
									})}
								</Box>
								<Divider />
							</Box>
						))}
					</Box>
					<Box>
						{tempKey ? (
							<Box background="#fff" shadow="md" p={2} d="inline-block">
								{tempKey}
							</Box>
						) : null}
					</Box>
					<Box w="100%">
						<Center w="100%">
							<Button onClick={() => window.location.reload()} colorScheme="red" mr={3}>重新開始</Button>
						</Center>
					</Box>
				</>
			) : null}
		</Page>
	);
}
